import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StudentInstructionsAPIService } from "../services/studentinstructions/studentinstructions.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";
import { IpService } from "../services/ip/ip.service";
import { TimerService } from "../services/menu/timer.service";
import { GlobalVariableService } from "../shared/globalvariable.service";
import { audioUpdate } from "../services/profile/profile";
import { ToastrService } from "ngx-toastr";
import { ProfileService } from "../services/profile/profile.service";
import { url } from "../services/baseurl";
@Component({
  selector: "app-puzzleaudio",
  templateUrl: "./puzzleaudio.component.html",
  styleUrls: ["./puzzleaudio.component.scss"],
})
export class PuzzleAudioComponent implements OnInit {
  colortheme_base_sec = ["#181C20", "#F0F0F0", "#FFFFFF"];
  colortheme_txt1_Arr = ["rgba(255, 255, 255, 0.8)", "black"];
  colortheme = ["black", "white"];
  colortheme_txt: any;
  colortheme_txt1: any;
  colortheme_bg_base_sec: any;
  colortheme_bg_base: any;
  // showinstruction: boolean = false;
  response: any;
  studentInstructionsData: any = [];
  instruction_image = "../../assets/images/music-image.png";
  istimerchange: boolean;
  currentissessioncompleted: boolean;
  currentisallattemptcompleted: boolean;
  currentisanygameincontinue: boolean;
  currentcompletedsessioncount: number;
  currentisfirstloginoftheday: boolean;
  currentpage: string = "";
  divId: number = 0;
  @ViewChild("showpuzzleaudio", { static: false }) showpuzzleaudio;
  baseurl = new url();
  soundurl1: any;
  soundurl2: any;
  soundurl3: any;
  soundurl4: any;
  soundurl5: any;
  soundurl6: any;
  soundurl7: any;
  @ViewChild("audio1", { static: false }) audio1: any;
  @ViewChild("audio2", { static: false }) audio2: any;
  @ViewChild("audio3", { static: false }) audio3: any;
  @ViewChild("audio4", { static: false }) audio4: any;
  @ViewChild("audio5", { static: false }) audio5: any;
  @ViewChild("audio6", { static: false }) audio6: any;
  @ViewChild("langCheck", { static: false }) langCheck: any;
  audiomute1: number = 0.1;
  audiomute2: number = 0.1;
  audiomute3: number = 0.1;
  audiomute4: number = 0.1;
  audiomute5: number = 0.1;
  audiomute6: number = 0.1;
  audioupdate = new audioUpdate();
  profileWords: any;
  constructor(
    private modalService: NgbModal,
    private StudentInstructionsService: StudentInstructionsAPIService,
    private ngxLoaderService: NgxUiLoaderService,
    private router: Router,
    private ip: IpService,
    private timeserv: TimerService,
    private globalvariable: GlobalVariableService,
    private toastr: ToastrService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.colortheme_bg_base =
      this.colortheme[parseInt(localStorage.getItem("isdark"))];

    this.colortheme_bg_base_sec =
      this.colortheme_base_sec[parseInt(localStorage.getItem("isdark"))];
    this.colortheme_txt1 =
      this.colortheme_txt1_Arr[parseInt(localStorage.getItem("isdark"))];
    if (parseInt(localStorage.getItem("isdark")) == 0) {
      this.colortheme_txt = this.colortheme[1];
    } else {
      this.colortheme_txt = this.colortheme[0];
    }

    this.timeserv.currenttimerchange.subscribe(
      (timeChange) => (this.istimerchange = timeChange)
    );
    console.log("this.istimerchange====intr" + this.istimerchange);
    this.profileWords = [
      ...JSON.parse(localStorage.getItem("langwords") || "[]"),
    ];

    //////////////////////////////////////////////
    this.soundurl1 = this.baseurl.soundUrl + "sound_1.mp3";
    this.soundurl2 = this.baseurl.soundUrl + "sound_2.mp3";
    this.soundurl3 = this.baseurl.soundUrl + "sound_3.mp3";
    this.soundurl4 = this.baseurl.soundUrl + "sound_4.mp3";
    this.soundurl5 = this.baseurl.soundUrl + "sound_5.mp3";
    this.soundurl6 = this.baseurl.soundUrl + "sound_6.mp3";
    this.soundurl7 = this.baseurl.soundUrl + "sound_7.mp3";
  }

  showAccordian(div_id: number) {
    console.log(div_id);
    this.divId = div_id;
  }
  showPuzzleAudioFn() {
    let date_val: Date;
    date_val = new Date();
    let hash_val = this.ip.gethash(date_val);
    let data = {
      uid: localStorage.getItem("uid"),
      page_ref: this.currentpage,
      currentlanguage: parseInt(localStorage.getItem("currentlangNo")),
      scenerio_id: 1,
      istimeexpired: this.istimerchange ? 1 : 0,
      issessioncompleted: this.currentissessioncompleted ? 1 : 0,
      isallattemptcompleted: this.currentisallattemptcompleted ? 1 : 0,
      isanygameincontinue: this.currentisanygameincontinue ? 1 : 0,
      completedsessioncount: this.currentcompletedsessioncount,
      isfirstloginoftheday: localStorage.getItem("logincount") === "1" ? 1 : 0,
      timestamp: date_val.toString(),
      hashcode: hash_val,
    };
    this.ngxLoaderService.startLoader("loader-master");
    this.modalService.open(this.showpuzzleaudio, { centered: true });
    this.ngxLoaderService.stopLoader("loader-master");
  }

  selectaudio(e) {
    //////function for updating audio  - starts//////
    this.audioupdate.uid = localStorage.getItem("uid");
    console.log(this.audioupdate);
    let date_val: Date;
    date_val = new Date();
    let hash_val = this.ip.gethash(date_val);
    this.audioupdate.timestamp = date_val.toString();
    this.audioupdate.hashcode = hash_val;
    this.audioupdate.audioname = "Music " + e.target.id;    
    this.profileService.updateaudio(this.audioupdate).subscribe(
      (res) => {
        this.response = JSON.parse(JSON.stringify(res));
        if (this.response.code == "SA000") {
          console.log("updateaudio success");
          this.toastr.success(
            this.profileWords[48] +
              " " +
              e.target.id +
              " " +
              this.profileWords[46]
          );
          localStorage.setItem("music_val", e.target.id);
        } else {
          if (
            this.response.code == "SA1061" ||
            this.response.code == "SA1041" ||
            this.response.code == "SA1040" ||
            this.response.code == "SA1039"
          ) {
            localStorage.clear();
            this.router.navigateByUrl("/login");
          }
        }
      },
      (err) => {}
    );
    //////function for updating audio  - ends//////
  }
}
