import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StudentInstructionsAPIService } from "../services/studentinstructions/studentinstructions.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";
import { IpService } from "../services/ip/ip.service";
import { TimerService } from "../services/menu/timer.service";
import { GlobalVariableService } from "../shared/globalvariable.service";
@Component({
  selector: "app-instructions",
  templateUrl: "./instructions.component.html",
  styleUrls: ["./instructions.component.scss"],
})
export class InstructionsComponent implements OnInit {
  colortheme_base_sec = ["#181C20", "#F0F0F0", "#FFFFFF"];
  colortheme_txt1_Arr = ["rgba(255, 255, 255, 0.8)", "black"];
  colortheme = ["black", "white"];
  colortheme_txt: any;
  colortheme_txt1: any;
  colortheme_bg_base_sec: any;
  colortheme_bg_base: any;
  // showinstruction: boolean = false;
  response: any;
  studentInstructionsData: any = [];
  instruction_image = "../../assets/images/instruction.png";
  istimerchange: boolean;
  currentissessioncompleted: boolean;
  currentisallattemptcompleted: boolean;
  currentisanygameincontinue: boolean;
  currentcompletedsessioncount: number;
  currentisfirstloginoftheday: boolean;
  currentpage:string = "";
  divId:number= 0;
  @ViewChild("showinstructions", { static: false }) showinstructions;
  constructor(
    private modalService: NgbModal,
    private StudentInstructionsService: StudentInstructionsAPIService,
    private ngxLoaderService: NgxUiLoaderService,
    private router: Router,
    private ip: IpService,
    private timeserv: TimerService,
    private globalvariable: GlobalVariableService
  ) {}

  ngOnInit() {
    this.colortheme_bg_base =
      this.colortheme[parseInt(localStorage.getItem("isdark"))];

    this.colortheme_bg_base_sec =
      this.colortheme_base_sec[parseInt(localStorage.getItem("isdark"))];
    this.colortheme_txt1 =
      this.colortheme_txt1_Arr[parseInt(localStorage.getItem("isdark"))];
    if (parseInt(localStorage.getItem("isdark")) == 0) {
      this.colortheme_txt = this.colortheme[1];
    } else {
      this.colortheme_txt = this.colortheme[0];
    }

    this.timeserv.currenttimerchange.subscribe(
      (timeChange) => (this.istimerchange = timeChange)
    );
    console.log("this.istimerchange====intr" + this.istimerchange);
  }

  showAccordian(div_id:number){
    console.log(div_id)
    this.divId = div_id;
  }
  showInstructionFn() {
    this.divId = 0;
    this.globalvariable.currentissessioncompletedchange.subscribe(
      (changeValue) => (this.currentissessioncompleted = changeValue)
    );
    this.globalvariable.currentisallattemptcompletedchange.subscribe(
      (changeValue) => (this.currentisallattemptcompleted = changeValue)
    );
    this.globalvariable.currentisanygameincontinuechange.subscribe(
      (changeValue) => (this.currentisanygameincontinue = changeValue)
    );
    this.globalvariable.currentcompletedsessioncountchange.subscribe(
      (changeValue) => (this.currentcompletedsessioncount = changeValue)
    );
    this.globalvariable.currentisfirstloginofthedaychange.subscribe(
      (changeValue) => (this.currentisfirstloginoftheday = changeValue)
    );
    this.globalvariable.currentpagechange.subscribe(
      (changeValue) => (this.currentpage = changeValue)
    );
    let date_val: Date;
    date_val = new Date();
    let hash_val = this.ip.gethash(date_val);
    let data = {
      uid: localStorage.getItem("uid"),
      page_ref: this.currentpage,
      currentlanguage: parseInt(localStorage.getItem("currentlangNo")),
      scenerio_id: 1,
      istimeexpired: this.istimerchange ? 1 : 0,
      issessioncompleted: this.currentissessioncompleted ? 1 : 0,
      isallattemptcompleted: this.currentisallattemptcompleted ? 1 : 0,
      isanygameincontinue: this.currentisanygameincontinue ? 1 : 0,
      completedsessioncount: this.currentcompletedsessioncount,
      isfirstloginoftheday: localStorage.getItem("logincount") === "1" ? 1 : 0,
      timestamp: date_val.toString(),
      hashcode: hash_val,
    };
    this.ngxLoaderService.startLoader("loader-master");
    this.StudentInstructionsService.getStudentInstructions(data).subscribe(
      (res) => {
        this.response = JSON.parse(JSON.stringify(res));
        this.ngxLoaderService.stopLoader("loader-master");
        //console.log('logout=>',this.response1)
        if (this.response.code == "SA000") {
          this.studentInstructionsData = this.response.data;
          this.modalService.open(this.showinstructions, { centered: true });
        } else {
          console.log(this.response.code);
          if (
            this.response.code == "SA1061" ||
            this.response.code == "SA1041" ||
            this.response.code == "SA1040" ||
            this.response.code == "SA1039"
          ) {
            localStorage.clear();
            this.router.navigateByUrl("/login");
          }
        }
      }
    );
  }
}
